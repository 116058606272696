<template>
  <v-card outlined>
    <v-card-title>{{ statistica.tipologia }}</v-card-title>
    <v-row>
      <v-col cols="6">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Metrica</th>
                <th class="text-right">Valore</th>
              </tr>
            </thead>
            <tbody>
              <tr><td>Aderito</td><td class="text-right">{{ statistica.aderito }}</td></tr>
              <tr><td>Contrattualizzato</td><td class="text-right">{{ statistica.contrattualizzato.true }}</td></tr>
              <tr><td>In asseverazione</td><td class="text-right">{{ statistica.inAsseverazione }}</td></tr>
              <tr><td>Asseverati positivi</td><td class="text-right">{{ statistica.asseveratiPositivi }}</td></tr>
              <tr><td>In rilavorazione</td><td class="text-right">{{ statistica.inRilavorazione }}</td></tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <apexchart type="bar" :options="chartOptions" :series="series" ></apexchart>
      </v-col>
    </v-row>
    <v-card-actions>
<!--       <v-btn
        outlined
        text
      >
        Button
      </v-btn> -->
    </v-card-actions>    
  </v-card>
</template>

<script>
  export default {
    props: {
      statistica: Object
    },
    data (vm) {
      return {
        series: [
            {
              data: [
                {
                  x: 'Contratti',
                  y: vm.statistica.contrattualizzato.true,
                  goals: [
                    {
                      name: 'Aderito',
                      value: vm.statistica.aderito,
                      strokeHeight: 2,
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: 'Positivi',
                  y: vm.statistica.asseveratiPositivi,
                  goals: [
                    {
                      name: 'Aderito',
                      value: vm.statistica.aderito,
                      strokeHeight: 2,
                      strokeColor: '#775DD0'
                    }
                  ]
                },
                {
                  x: 'Asseverazione',
                  y: vm.statistica.inAsseverazione,
                  goals: [
                  ]
                },
                {
                  x: 'Rilavorazione',
                  y: vm.statistica.inRilavorazione,
                  goals: [
                  ]
                }                                                       
              ]
            }
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '60%',
                distributed: true
              }
            },
            colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa'],            
            dataLabels: {
              enabled: false     
            },
            xaxis: {
              categories: [
                'Contratti',
                'Positivi',
                'Asseverazione',
                'Rilavorazione'
              ]
            },
            yaxis: {
              labels: {
                show: false
              }
            },         
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },            
             legend: {
              show: false
/*               showForSingleSeries: true,
              customLegendItems: ['Contrattualizzato', 'Aderito', 'Asseverato'],
              markers: {
                fillColors: ['#008FFB', '#775DD0', '#00E396' ]
              } */
            },
            tooltip: {
              theme: 'dark',
              x: {
                show: false
              },
              y: {
                title: {
                  formatter: function (val, opt) {
                    return opt.w.globals.labels[opt.dataPointIndex]
                  }
                }
              }
            }            
          }
      }
    }
  }
</script>